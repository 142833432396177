exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agile-jsx": () => import("./../../../src/pages/agile.jsx" /* webpackChunkName: "component---src-pages-agile-jsx" */),
  "component---src-pages-awards-jsx": () => import("./../../../src/pages/awards.jsx" /* webpackChunkName: "component---src-pages-awards-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookie-jsx": () => import("./../../../src/pages/cookie.jsx" /* webpackChunkName: "component---src-pages-cookie-jsx" */),
  "component---src-pages-form-error-jsx": () => import("./../../../src/pages/form-error.jsx" /* webpackChunkName: "component---src-pages-form-error-jsx" */),
  "component---src-pages-form-success-jsx": () => import("./../../../src/pages/form-success.jsx" /* webpackChunkName: "component---src-pages-form-success-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ourapproach-jsx": () => import("./../../../src/pages/ourapproach.jsx" /* webpackChunkName: "component---src-pages-ourapproach-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-services-app-development-jsx": () => import("./../../../src/pages/services/appDevelopment.jsx" /* webpackChunkName: "component---src-pages-services-app-development-jsx" */),
  "component---src-pages-services-big-data-jsx": () => import("./../../../src/pages/services/bigData.jsx" /* webpackChunkName: "component---src-pages-services-big-data-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-legacy-jsx": () => import("./../../../src/pages/services/legacy.jsx" /* webpackChunkName: "component---src-pages-services-legacy-jsx" */),
  "component---src-pages-services-resourcing-jsx": () => import("./../../../src/pages/services/resourcing.jsx" /* webpackChunkName: "component---src-pages-services-resourcing-jsx" */),
  "component---src-pages-services-saas-jsx": () => import("./../../../src/pages/services/saas.jsx" /* webpackChunkName: "component---src-pages-services-saas-jsx" */),
  "component---src-pages-services-web-development-jsx": () => import("./../../../src/pages/services/webDevelopment.jsx" /* webpackChunkName: "component---src-pages-services-web-development-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

